export const baseURL = 'https://it.blue-ocean.mirruba-jewellery.com/api/';
export const imageURL = 'https://it.blue-ocean.mirruba-jewellery.com/';

export const loginAPI = `${baseURL}auth/login`

//Services
export const CreateServiceAPI = `${baseURL}service/add`
export const UpdateServiceAPI = `${baseURL}service/update/`
export const DeleteServiceAPI = `${baseURL}service/delete/`
export const GetServicesAPI = `${baseURL}service/get?page=`
export const GetContentsByServiceAPI = `${baseURL}service/get/`

//Content
export const CreateContentAPI = `${baseURL}content/add/`
export const UpdateContentAPI = `${baseURL}content/update/`
export const DeleteContentAPI = `${baseURL}content/delete/`

//Slider
export const CreateSliderAPI = `${baseURL}slider/add`
export const UpdateSliderAPI = `${baseURL}slider/update/`
export const DeleteSliderAPI = `${baseURL}slider/delete/`
export const GetSlidersAPI = `${baseURL}slider/get?page=`

//About Us
export const GetAboutUsAPI = `${baseURL}about/get`
export const UpdateAboutUsAPI = `${baseURL}about/update`
