import React from "react";
import LoginForm from "./LoginForm";
import { motion } from "framer-motion";
import "./styles/Login.scss";

const Login = () => {
  return (
    <div className="login">
      <motion.div
        initial={{ scale: 0.5, opacity: 0, x: "-100%" }}
        animate={{ scale: 1, opacity: 1, x: "0%" }}
        transition={{ type: "spring", stiffness: 100, duration: 2 }}
        className="background-image"
      >
        <motion.h1
          className="welcome-phrase"
          initial={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          Blue Ocean
        </motion.h1>
      </motion.div>

      <motion.div
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 200 }}
      >
        <LoginForm />
      </motion.div>
    </div>
  );
};

export default Login;
