import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import NotFound from "./components/notfound/NotFound";
import AboutUs from "./pages/about-us/AboutUs";
import Login from "./pages/login/Login";
import Services from "./pages/services/Services";
import ShowService from "./pages/services/actions/ShowService";
import Sliders from "./pages/slider/Sliders";

const AppRoutes = () => {

    const isLoginPage = window.location.pathname === '/';

    return (
        <Router>
            {!isLoginPage && <Sidebar />}
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/slider" element={<Sliders />} />
                    <Route path="/services">
                        <Route index element={<Services />} />
                        <Route path=':serviceId' element={<ShowService />} />
                    </Route>
                    <Route path="/about">
                        <Route index element={<AboutUs />} />
                    </Route>
                </Routes>
            </div>
        </Router>
    );
};

export default AppRoutes;
