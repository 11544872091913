import { configureStore } from '@reduxjs/toolkit';
import ServiceReducer from './reducers/ServiceReducer';
import AboutUsReducer from './reducers/AboutUsReducer';
import AuthReducer from './reducers/AuthReducer';
import ContentReducer from './reducers/ContentReducer';
import SliderReducer from './reducers/SliderReducer';


const store = configureStore({
    reducer: {
        auth: AuthReducer,
        services: ServiceReducer,
        slider: SliderReducer,
        aboutUs: AboutUsReducer,
        content: ContentReducer
    }
});
export default store