import { createSlice } from '@reduxjs/toolkit';
import { AddServiceAction, DeleteServiceAction, GetServiceContentAction, GetServicesAction, UpdateServiceAction } from '../actions/ServiceAction';
export const serviceReducer = createSlice({
    name: "Service Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        services: [],
        content: []
    },
    reducers: {
        contentUnmount: (state) => {
            state.content = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetServicesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetServicesAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.services = payload.data.data;
                state.totalItems = payload.data.totalItems;
                state.currentPage = payload.data.currentPage;
            })
            .addCase(GetServicesAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(AddServiceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddServiceAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(AddServiceAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateServiceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateServiceAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateServiceAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteServiceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteServiceAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteServiceAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(GetServiceContentAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetServiceContentAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
                state.content = payload.data;
            })
            .addCase(GetServiceContentAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
    }
});
export default serviceReducer.reducer;
export const { contentUnmount } = serviceReducer.actions;
