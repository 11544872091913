import React, { useEffect, useState } from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";
import { BiCarousel } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";

const Sidebar = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    let path = location.pathname.slice(1);
    let currentPath = path.split("/")[0];
    setActiveTab(currentPath);
  }, [location.pathname]);

  const setTab = (place) => {
    localStorage.setItem("tab", place);
    setActiveTab(place);
  };

  return (
    <>
      <div className="sidebar">
        <div className="sidebar-logo">
          <img src={"/logo.png"} alt="Logo" className="logo-image" />
        </div>
        <ul className="sidebar-list">
          <Link to={"about"} onClick={() => setTab("about")}>
            <li
              className={`sidebar-item ${
                activeTab === "about" ? "visited" : ""
              }`}
            >
              <FaCircleExclamation />
              About
            </li>
          </Link>

          <Link to={"services"} onClick={() => setTab("services")}>
            <li
              className={`sidebar-item ${
                activeTab === "services" ? "visited" : ""
              }`}
            >
              <GrServices />
              Services
            </li>
          </Link>

          <Link to={"slider"} onClick={() => setTab("slider")}>
            <li
              className={`sidebar-item ${
                activeTab === "slider" ? "visited" : ""
              }`}
            >
              <BiCarousel />
              Slider
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
