import React, { useEffect, useState } from "react";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Button } from "primereact/button";
import AboutUsCard from "../../components/about-us-card/AboutUsCard";
import { MdOutlinePlace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { GetAboutUsAction } from "../../redux/actions/AboutUsAction";
import { FiPhone } from "react-icons/fi";
import { Dialog } from "primereact/dialog";
import UpdateAboutUs from "./actions/UpdateAboutUs";
import "./styles/AboutUs.scss";
import LoadingFS from "../../components/loading/LoadingFS";

const AboutUs = () => {
  const dispatch = useDispatch();
  const { loading, aboutUs } = useSelector((state) => state.aboutUs);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    dispatch(GetAboutUsAction());
  }, [dispatch]);

  const data = [
    {
      title: "Facebook",
      link: aboutUs?.facebook,
      image: "./social-media/facebook.png",
    },
    {
      title: "Apple",
      link: aboutUs?.apple,
      image: "./social-media/linkedin.png",
    },
    {
      title: "Android",
      link: aboutUs?.android,
      image: "./social-media/mail.png",
    },
    {
      title: "Instagram",
      link: aboutUs?.instagram,
      image: "./social-media/instagram.png",
    },
  ];

  return (
    <div className="about-us cs-container">
      {loading && <LoadingFS />}
      <div className="header">
        <h2 className="title">About Us</h2>

        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-warning"
          aria-label="Submit"
          onClick={() => setUpdateModal(true)}
        />
      </div>

      <div className="information">
        <span>
          <MdOutlinePlace className="icon" />
          <h4>{aboutUs.address}</h4>
        </span>
        <span>
          <FiPhone className="icon" />
          <h4>{aboutUs.phone_mobile}</h4>
        </span>
      </div>

      <Splitter className="splitter" layout="vertical">
        <SplitterPanel className="splitter-panel ar">
          {aboutUs.main_description_ar}
        </SplitterPanel>
        <SplitterPanel className="splitter-panel">
          {aboutUs.main_description_en}
        </SplitterPanel>
      </Splitter>

      <div className="grid">
        {data.map((item, index) => {
          return (
            <AboutUsCard
              key={index}
              index={index}
              title={item.title}
              link={item.link}
              image={item.image}
            />
          );
        })}
      </div>

      <Dialog
        header="Update Project"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateAboutUs updateModal={(e) => setUpdateModal(e)} />
      </Dialog>
    </div>
  );
};

export default AboutUs;
