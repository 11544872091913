import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { showError, showSuccess } from "../../../utils/ToastService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  GetServicesAction,
  UpdateServiceAction,
} from "../../../redux/actions/ServiceAction";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { imageURL } from "../../../redux/Api";
import { toast } from "react-toastify";

const UpdateService = ({ rowData, basicRows, updateModal }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.services);
  const [serviceInfo, setServiceInfo] = useState([]);
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
    };
    formData.append("title_en", serviceInfo.titleEn ?? rowData.title_en);
    formData.append(
      "description_en",
      serviceInfo.descriptionEn ?? rowData.description_en
    );
    formData.append("title_ar", serviceInfo.titleAr ?? rowData.title_ar);
    formData.append(
      "description_ar",
      serviceInfo.descriptionAr ?? rowData.description_ar
    );

    if (serviceInfo.image !== undefined)
      formData.append("image", serviceInfo.image);

    let data = { id: rowData.id, obj: formData };

    dispatch(UpdateServiceAction(data))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          updateModal(false);
          dispatch(GetServicesAction(info));
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <div className="section input-wrapper file-upload">
          <div className="image-preview">
            {serviceInfo.image ? (
              <img src={serviceInfo.image.objectURL} alt="project-preview" />
            ) : (
              <img src={imageURL + rowData.image_path} alt="project-preview" />
            )}
          </div>
          <h4 className="">Service Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onSelect={(event) =>
              setServiceInfo({ ...serviceInfo, image: event.files[0] })
            }
          />
        </div>

        <div className="section">
          <h4 className="">English</h4>
          <div className="input-wrapper">
            <InputText
              defaultValue={rowData.title_en}
              required
              className="custom-input"
              type="text"
              placeholder="Service Title"
              onChange={(e) =>
                setServiceInfo({ ...serviceInfo, titleEn: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              defaultValue={rowData.description_en}
              required
              rows={5}
              className="custom-input"
              id="address"
              autoResize
              placeholder="Service Description"
              onChange={(e) =>
                setServiceInfo({
                  ...serviceInfo,
                  descriptionEn: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="section">
          <h4 className="">Arabic</h4>
          <div className="input-wrapper">
            <InputText
              defaultValue={rowData.title_ar}
              required
              className="custom-input ar"
              type="text"
              placeholder="عنوان الخدمة"
              onChange={(e) =>
                setServiceInfo({
                  ...serviceInfo,
                  titleAr: e.target.value,
                })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              defaultValue={rowData.description_ar}
              required
              rows={5}
              className="custom-input ar"
              id="address"
              autoResize
              placeholder="وصف الخدمة"
              onChange={(e) =>
                setServiceInfo({
                  ...serviceInfo,
                  descriptionAr: e.target.value,
                })
              }
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
            type="submit"
          />
          <Button
            size="small"
            className="info"
            label="Update"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateService;
