import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateContentAPI, DeleteContentAPI, UpdateContentAPI } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const AddContentAction = createAsyncThunk("admin/content/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(CreateContentAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateContentAction = createAsyncThunk("admin/content/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.put(UpdateContentAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteContentAction = createAsyncThunk("admin/content/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteContentAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});