import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateSliderAPI, DeleteSliderAPI, GetSlidersAPI, UpdateSliderAPI } from "../Api";
import axiosInstance, { errorMessage } from "../constant";

export const GetSlidersAction = createAsyncThunk("admin/sliders/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetSlidersAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddSliderAction = createAsyncThunk("admin/slider/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(CreateSliderAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateSliderAction = createAsyncThunk("admin/slider/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.put(UpdateSliderAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteSliderAction = createAsyncThunk("admin/slider/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteSliderAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});