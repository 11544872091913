import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import {
  GetAboutUsAction,
  UpdateAboutUsAction,
} from "../../../redux/actions/AboutUsAction";
import { toast } from "react-toastify";

const UpdateAboutUs = ({ updateModal }) => {
  const dispatch = useDispatch();
  const { loading, aboutUs } = useSelector((state) => state.aboutUs);
  const [aboutInfo, setAboutInfo] = useState([]);

  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("facebook", aboutInfo.facebook ?? aboutUs.facebook);
    formData.append("instagram", aboutInfo.instagram ?? aboutUs.instagram);
    formData.append("android", aboutInfo.android ?? aboutUs.android);
    formData.append("apple", aboutInfo.apple ?? aboutUs.apple);
    formData.append(
      "phone_mobile",
      aboutInfo.phone_mobile ?? aboutUs.phone_mobile
    );
    formData.append("address", aboutInfo.address ?? aboutUs.address);
    formData.append(
      "main_description_en",
      aboutInfo.main_description_en ?? aboutUs.main_description_en
    );
    formData.append(
      "main_description_ar",
      aboutInfo.main_description_ar ?? aboutUs.main_description_ar
    );
    console.log(aboutInfo);
    dispatch(UpdateAboutUsAction(formData))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          updateModal(false);
          dispatch(GetAboutUsAction());
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="containerAS">
      <form onSubmit={submitHandler}>
        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-instagram"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.instagram}
              type="text"
              placeholder="Instagram Link"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, instagram: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-facebook"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.facebook}
              type="text"
              placeholder="Facebook Link"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, facebook: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-phone"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.phone_mobile}
              type="text"
              placeholder="Mobile Phone"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, phone_mobile: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-map-marker"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.address}
              type="text"
              placeholder="Address"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, address: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-android"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.android}
              type="text"
              placeholder="Android Link"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, android: e.target.value })
              }
            />
          </div>
        </div>

        <div className="input-wrapper">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-apple"></i>
            </span>
            <InputText
              required
              defaultValue={aboutUs.apple}
              type="text"
              placeholder="Apple Link"
              onChange={(e) =>
                setAboutInfo({ ...aboutInfo, apple: e.target.value })
              }
            />
          </div>
        </div>

        <div className="section">
          <h4 className="">English</h4>
          <div className="input-wrapper">
            <InputTextarea
              minLength={50}
              maxLength={600}
              required
              rows={5}
              defaultValue={aboutUs.main_description_en}
              className="custom-input"
              autoResize
              placeholder="About Us Description"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  main_description_en: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="section">
          <h4 className="">Arabic</h4>
          <div className="input-wrapper">
            <InputTextarea
              minLength={50}
              required
              rows={5}
              defaultValue={aboutUs.main_description_ar}
              className="custom-input ar"
              autoResize
              placeholder="About Us Description"
              onChange={(e) =>
                setAboutInfo({
                  ...aboutInfo,
                  main_description_ar: e.target.value,
                })
              }
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
            type="submit"
          />
          <Button
            size="small"
            className="info"
            label="Update"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateAboutUs;
