import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import SliderDT from "./SliderDT";
import DeleteService from "./actions/DeleteSlider";
import AddSlider from "./actions/AddSlider";
import UpdateSlider from "./actions/UpdateSlider";

const Sliders = ({ toast }) => {
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { loading } = useSelector((state) => state.slider);

  return (
    <div>
      {loading && <LoadingFS />}
      <SliderDT
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add Slider"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
      >
        <AddSlider
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update Service"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateSlider
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete Slider"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteService
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default Sliders;
