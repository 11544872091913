import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
  AddContentAction,
  DeleteContentAction,
  UpdateContentAction,
} from "../../../redux/actions/ContentAction";
import { GetServiceContentAction } from "../../../redux/actions/ServiceAction";
import { contentUnmount } from "../../../redux/reducers/ServiceReducer";
import { toast } from "react-toastify";
import "../styles/ShowService.scss";
import LoadingFS from "../../../components/loading/LoadingFS";

const ShowService = () => {
  const dispatch = useDispatch();
  const { serviceId } = useParams();
  const { content } = useSelector((state) => state.services);
  const mainLoader = useSelector((state) => state.services.loading);
  const { loading } = useSelector((state) => state.content);
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeURIComponent(encodedData));
  useEffect(() => {
    let info = {
      id: serviceId,
      isPaginate: 0,
    };
    dispatch(GetServiceContentAction(info));
    return () => {
      dispatch(contentUnmount());
    };
  }, [dispatch, serviceId]);

  const addOrUpdateItem = (e) => {
    e.preventDefault();
    let obj = new FormData();
    if (selectedItem) {
      obj.append("description_ar", descriptionAr);
      obj.append("description_en", descriptionEn);
      let info = {
        id: selectedItem.id,
        obj: obj,
      };
      dispatch(UpdateContentAction(info))
        .then(({ payload }) => {
          console.log(payload);
          if (payload?.data) {
            toast.success(payload.message);
            setDisplayDialog(false);
            dispatch(GetServiceContentAction({ id: serviceId, isPaginate: 0 }));
          } else {
            toast.error(payload.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      obj.append("description_ar", descriptionAr);
      obj.append("description_en", descriptionEn);
      let info = {
        id: serviceId,
        obj: obj,
      };
      dispatch(AddContentAction(info))
        .then(({ payload }) => {
          console.log(payload);
          if (payload?.data) {
            toast.success(payload.message);
            setDisplayDialog(false);
            dispatch(GetServiceContentAction({ id: serviceId, isPaginate: 0 }));
          } else {
            toast.error(payload.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const confirmDelete = (item) => {
    confirmDialog({
      message: "Are you sure you want to delete this item?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        dispatch(DeleteContentAction(item.id))
          .then(({ payload }) => {
            console.log(payload);
            if (payload?.status) {
              toast.success(payload.message);
              dispatch(
                GetServiceContentAction({ id: serviceId, isPaginate: 0 })
              );
            } else {
              toast.error(payload.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      },
    });
  };

  const renderDialog = () => {
    return (
      <Dialog
        visible={displayDialog}
        onHide={() => setDisplayDialog(false)}
        header={selectedItem ? "Update Item" : "Add New Item"}
        dismissableMask
        resizable={false}
        appendTo={"self"}
      >
        <form onSubmit={addOrUpdateItem}>
          <div className="input-wrapper">
            <InputText
              placeholder="شرح (عربي)"
              className="custom-input ar"
              value={descriptionAr}
              onChange={(e) => setDescriptionAr(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <InputText
              placeholder="Description (English)"
              className="custom-input"
              value={descriptionEn}
              onChange={(e) => setDescriptionEn(e.target.value)}
            />
          </div>
          <span className="actions">
            <Button
              size="small"
              className="dark"
              label="Cancel"
              icon="pi pi-times"
              onClick={() => setDisplayDialog(false)}
              type="submit"
            />
            <Button
              size="small"
              className="info"
              label={"Update"}
              icon="pi pi-check"
              type="submit"
              loading={loading}
            />
          </span>
        </form>
      </Dialog>
    );
  };

  return (
    <div className="cs-container">
      {mainLoader && <LoadingFS />}
      <div className="button-container">
        <h3>{decodedData}</h3>
        <Button
          size="small"
          icon="pi pi-plus"
          className="p-button-rounded p-button-text p-button-warning"
          onClick={() => {
            setDisplayDialog(true);
            setSelectedItem(null);
            setDescriptionAr("");
            setDescriptionEn("");
          }}
        />
      </div>
      {content.map((item, index) => {
        return (
          <div key={index} className="item-container">
            <fieldset>
              <legend>English Description</legend>
              <p>{item.description_en}</p>
            </fieldset>
            <fieldset>
              <legend>Arabic Description</legend>
              <p className="arabic-text">{item.description_ar}</p>
            </fieldset>
            <div className="action-buttons">
              <Button
                icon="pi pi-pencil"
                text
                rounded
                className="p-button-success"
                onClick={() => {
                  setDisplayDialog(true);
                  setSelectedItem(item);
                  setDescriptionAr(item.description_ar);
                  setDescriptionEn(item.description_en);
                }}
              />
              <Button
                icon="pi pi-trash"
                text
                rounded
                className="p-button-danger"
                onClick={() => confirmDelete(item)}
              />
            </div>
          </div>
        );
      })}
      {renderDialog()}
    </div>
  );
};

export default ShowService;
