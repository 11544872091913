import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import {
  DeleteServiceAction,
  GetServicesAction,
} from "../../../redux/actions/ServiceAction";
import { toast } from "react-toastify";

const DeleteService = ({ rowData, basicRows, deleteModal }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.services);
  const submitHandler = (e) => {
    e.preventDefault();
    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
    };
    dispatch(DeleteServiceAction(rowData.id))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.status) {
          toast.success(payload.message);
          deleteModal(false);
          dispatch(GetServicesAction(info));
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <h5>Do you like to delete this service ?</h5>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => deleteModal(false)}
          />
          <Button
            size="small"
            className="danger"
            label="Delete"
            icon="pi pi-trash"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default DeleteService;
