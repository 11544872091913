import { createSlice } from '@reduxjs/toolkit';
import { AddContentAction, DeleteContentAction, UpdateContentAction } from '../actions/ContentAction';
export const ContentReducer = createSlice({
    name: "Content Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(AddContentAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddContentAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(AddContentAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateContentAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateContentAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateContentAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteContentAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteContentAction.fulfilled, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteContentAction.rejected, (state, { payload }) => {
                console.log(payload)
                state.loading = false;
            })

    }
});
export default ContentReducer.reducer;
export const { contentUnmount } = ContentReducer.actions;
