import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes';
import './assets/style.scss'

function App() {

  return (
    <div className="app">
      <AppRoutes />
      <ConfirmDialog />
      <ToastContainer draggable position='bottom-right' />
    </div>
  );
}

export default App;
