import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import {
  AddServiceAction,
  GetServicesAction,
} from "../../../redux/actions/ServiceAction";
import { toast } from "react-toastify";

const AddService = ({ basicRows, createModal }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.services);
  const [serviceInfo, setServiceInfo] = useState([]);
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(serviceInfo);
    let formData = new FormData();
    formData.append("title_en", serviceInfo.titleEn);
    formData.append("description_en", serviceInfo.descriptionEn);
    formData.append("title_ar", serviceInfo.titleAr);
    formData.append("description_ar", serviceInfo.descriptionAr);
    formData.append("image", serviceInfo.image);

    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
    };

    dispatch(AddServiceAction(formData))
      .then(({ payload }) => {
        console.log(payload);
        if (payload?.data) {
          toast.success(payload.message);
          createModal(false);
          dispatch(GetServicesAction(info));
        } else {
          toast.error(payload.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container">
      <form onSubmit={submitHandler}>
        <div className="input-wrapper file-upload">
          <h4 className="">Service Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onSelect={(event) =>
              setServiceInfo({ ...serviceInfo, image: event.files[0] })
            }
          />
        </div>

        <div className="section">
          <h4 className="">English</h4>
          <div className="input-wrapper">
            <InputText
              required
              className="custom-input"
              type="text"
              placeholder="Service Title"
              onChange={(e) =>
                setServiceInfo({ ...serviceInfo, titleEn: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              required
              rows={5}
              className="custom-input"
              id="address"
              autoResize
              placeholder="Service Description"
              onChange={(e) =>
                setServiceInfo({
                  ...serviceInfo,
                  descriptionEn: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="section">
          <h4 className="">Arabic</h4>
          <div className="input-wrapper">
            <InputText
              required
              className="custom-input ar"
              type="text"
              placeholder="عنوان الخدمة"
              onChange={(e) =>
                setServiceInfo({ ...serviceInfo, titleAr: e.target.value })
              }
            />
          </div>

          <div className="input-wrapper">
            <InputTextarea
              required
              rows={5}
              className="custom-input ar"
              id="address"
              autoResize
              placeholder="وصف الخدمة"
              onChange={(e) =>
                setServiceInfo({
                  ...serviceInfo,
                  descriptionAr: e.target.value,
                })
              }
            />
          </div>
        </div>

        <span className="actions">
          <Button
            size="small"
            className="dark"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="submit"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
            disabled={!serviceInfo.image}
          />
        </span>
      </form>
    </div>
  );
};

export default AddService;
